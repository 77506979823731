<template>
  <div
    v-if="items.length > 0 || loading"
    class="container "
  >
    <v-row>
      <v-col cols="12">
        <div class="d-flex justify-space-between mb-md-4 ">
          <div class="d-flex align-center">
            <slot name="icon">
              <img
                v-svg-inline
                class="icon  primary--text mr-2"
                src="@/assets/images/icons/categories.svg"
                alt=""
              >
            </slot>

            <h2 class="font-weight-bold text-lg-h5 text-h6 align-self-center">
              {{ title }}
            </h2>
          </div>
          <router-link
            :to="url"
            class="text-decoration-none blue-grey--text darken-1 align-self-center"
          >
            {{ $t("Voir tous") }}
            <v-icon class="m-0 p-0">
              mdi-menu-right
            </v-icon>
          </router-link>
        </div>
      </v-col>
      <v-col
        cols="12"
        class="px-0"
      >
        <div class="">
          <VueSlickCarousel
            class="slick-secondary"
            :arrows="true"
            :dots="false"
            v-bind="slickSettings"
          >
            <div
              v-for="(item, index) in items"
              :key="index"
              class=""
            >
              <template v-if="loading">
                <v-skeleton-loader
                  type="image"
                />
                <v-skeleton-loader
                  type="article"
                  height="150"
                />
              </template>
              <card-cart
                v-else
                :content-img="item.img"
                :content-text="item.name"
                :counter="item.qty"
                :product="item"
                class="m-5 fill-height"
                style="height: 100%"
                @cartRemove="removeCart(item)"
                @cartAdd="addCart(item)"
              />
            </div>
            <template #prevArrow="arrowOption">
              <v-btn
                class="mx-2 slick-arrow prev slick-prev"
                fab
                dark
                small
                color="secondary"
              >
                <v-icon dark>
                  {{ arrowOption }}
                  mdi-arrow-left
                </v-icon>
              </v-btn>
            </template>
            <template #nextArrow="arrowOption">
              <v-btn
                class="mx-2 slick-arrow next slick-next"
                fab
                dark
                small
                color="secondary"
              >
                <v-icon dark>
                  {{ arrowOption }}
                  mdi-arrow-right
                </v-icon>
              </v-btn>
            </template>
          </VueSlickCarousel>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
  import CardCart from '@/components/cartCard/CardCart'
  import { mapGetters, mapActions, mapMutations } from 'vuex'
  export default {
    components: {
      CardCart,
    },
    props: {
      products: {
        type: Array,
        default: () => [],
      },
      title: {
        type: String,
        default: '',
      },
      url: {
        type: String,
        default: '/',
      },
      storePath: {
        type: String,
        default: '',
      },
      total: {
        type: Number,
        default: 20,
      },

    },
    computed: {
      ...mapGetters(['getProducts']),
      ...mapGetters(['getFlashSales']),
    },

    data () {
      return {
        loading: false,
        items: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
        slickSettings: {
          infinite: false,
          autoplay: true,
          speed: 500,
          slidesToShow: 4,
          slidesToScroll: 4,
          initialSlide: 0,

          responsive: [
            {
              breakpoint: 2100,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
                infinite: true,
              },
            },
            {
              breakpoint: 1900,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
                infinite: true,
              },
            },
            {
              breakpoint: 1800,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
                infinite: true,
              },
            },
            {
              breakpoint: 1264,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
              },
            },
            {
              breakpoint: 960,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                initialSlide: 2,
              },
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              },
            },
          ],
        },
        slickSettingsTwo: {
          infinite: false,
          speed: 500,
          slidesToShow: 4,
          slidesToScroll: 4,
          initialSlide: 0,

          responsive: [
            {
              breakpoint: 1700,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
              },
            },
            {
              breakpoint: 1264,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: true,
              },
            },
            {
              breakpoint: 960,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2,
              },
            },
            {
              breakpoint: 650,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1,
              },
            },
          ],
        },
        slickSettingsThree: {
          infinite: false,
          speed: 500,
          slidesToShow: 6,
          slidesToScroll: 6,
          initialSlide: 0,

          responsive: [
            {
              breakpoint: 1700,
              settings: {
                slidesToShow: 5,
                slidesToScroll: 6,
                infinite: true,
              },
            },
            {
              breakpoint: 1264,
              settings: {
                slidesToShow: 5,
                slidesToScroll: 4,
                infinite: true,
              },
            },
            {
              breakpoint: 960,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                initialSlide: 3,
              },
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
              },
            },
          ],
        },
      }
    },
    created () {
      if (!this.products.length) {
        this.getData()
      } else {
        this.items = this.products
      }
    },
    methods: {
      ...mapActions(['addCart', 'removeCart', 'fetchFlashSales']),
      ...mapMutations([
        'pushItem', // map `this.increment()` to `this.$store.commit('increment')`
        'removeItem', // map `this.increment()` to `this.$store.commit('increment')`

        // `mapMutations` also supports payloads:
        'setValue', // map `this.incrementBy(amount)` to `this.$store.commit('incrementBy', amount)`
      ]),
      getData () {
        this.loading = true
        // eslint-disable-next-line no-undef
        axios.get(this.url, { params: { per_page: this.total } }).then((response) => {
          if (response.data) {
            if (response.data.data) {
              this.items = response.data.data.map((ele) => {
                return {
                  ...ele,
                  title: ele.name,
                  categoryName: ele.category ? ele.category.name : '',
                  amount: ele.price_with_tax,
                  original_price: ele.price_with_tax,
                  promo: ele.promo,
                  qty: 0,
                }
              })
            } else {
              this.items = response.data.map((ele) => {
                return {
                  ...ele,
                  title: ele.name,
                  categoryName: ele.category ? ele.category.name : '',
                  amount: ele.price_with_tax,
                  original_price: ele.price_with_tax,
                  promo: ele.promo,
                  qty: 0,
                }
              })
            }
            if (this.storePath.length) {
              this.setValue({ type: this.storePath, value: this.items })
            }
            for (var i = 0; i < this.items.length; i++) {
              this.pushItem({ type: 'products', field: 'id', value: this.items[i].id, object: this.items[i] })
            }
          }
        }).then(() => {
          this.loading = false
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
    .top-categories-img {
      width: 385px;
      min-width: 100%;
      max-width: 100%;
    }
    .slick-secondary .slick-track {
      display: flex !important;
      gap: 12px;
    }
    .slick-track {
      display: flex !important;
      gap: 12px;
    }
</style>
