<template>
  <v-container
    v-if="items.length > 0 || loading"
    class=""
    :class="{'mb-60': $vuetify.breakpoint.mdAndUp }"
  >
    <v-row>
      <v-col cols="12">
        <div class="d-flex justify-space-between mb-md-4 ">
          <div class="d-flex align-center">
            <img
              v-svg-inline
              class="icon primary--text mr-2"
              src="@/assets/images/icons/categories.svg"
              alt=""
            >

            <h2 class="font-weight-bold text-lg-h5 text-h6 align-self-center">
              {{ title || $t('Boutiques') }}
            </h2>
          </div>
          <router-link
            v-if="to.length > 0"
            :to="to"
            class="text-decoration-none blue-grey--text darken-1 align-self-center"
          >
            {{ $t("Voir tous") }}
            <v-icon class="m-0 p-0">
              mdi-menu-right
            </v-icon>
          </router-link>
        </div>
      </v-col>
    </v-row>
    <div>
      <VueSlickCarousel
        class="slick-secondary"
        :arrows="true"
        :dots="false"
        v-bind="slickSettingsTwo"
      >
        <div
          v-for="(s, index) in items"
          :key="index"
          class="m-5"
        >
          <div

            class=""
          >
            <template v-if="loading">
              <v-skeleton-loader
                type="image"
                :height="$vuetify.breakpoint.mdAndUp?150:70"
              />
            </template>
            <router-link
              v-else
              :to="'/shops/'+s.user_id"
            >
              <base-card class="">
                <v-card-text
                  class="d-flex justify-content-center p-2 p-md-4 mx-auto pa-2 pa-md-4 pb-2 pb-md-4"
                >
                  <div class="card-overlay-item br-8 overflow-hidden">
                    <v-chip
                      class="mt-2 ma-2 p-absolute"
                      color="secondary"
                      small
                      pill
                    >
                      <v-avatar left>
                        <v-img
                          contain
                          :src="s.user.avatar_thumb || require('@/assets/images/avatars/1.png')"
                        />
                      </v-avatar>
                      {{ s.user.pseudo }}
                    </v-chip>
                    <!--                    <v-chip
                      class="mt-2 ma-2 p-absolute right-0"
                      color="grey lighten-3"
                      small
                    >
                      {{ s.name }}
                    </v-chip>-->
                    <a href="">
                      <img
                        class="top-categories-img br-8 mx-auto"
                        :src="s.user.cover || require('@/assets/images/banners/category-1.png')"
                        alt=""
                      >
                    </a>
                  </div>
                </v-card-text>
              </base-card>
            </router-link>
          </div>
        </div>
        <template #prevArrow="arrowOption">
          <v-btn
            class="mx-2 slick-arrow prev slick-prev"
            fab
            dark
            small
            x-small
            color="secondary"
          >
            <v-icon dark>
              {{ arrowOption }}
              mdi-arrow-left
            </v-icon>
          </v-btn>
        </template>
        <template #nextArrow="arrowOption">
          <v-btn
            class="mx-2 slick-arrow next slick-next"
            fab
            dark
            small
            x-small
            color="secondary"
          >
            <v-icon dark>
              {{ arrowOption }}
              mdi-arrow-right
            </v-icon>
          </v-btn>
        </template>
      </VueSlickCarousel>
    </div>
  </v-container>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex'
  export default {
    components: {
    },
    props: {
      title: { type: String, default: '' },
      to: { type: String, default: '/shops' },
      url: { type: String, default: '/shops' },
      storePath: {
        type: String,
        default: '',
      },
      shops: {
        type: Array,
        default: () => [],
      },
    },
    computed: {
      ...mapGetters(['getProducts']),
    },
    created () {
      if (!this.shops.length) {
        this.getData()
      } else {
        this.items = this.shops
      }
    },
    data () {
      return {
        loading: false,
        items: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
        topCategories: [
          {
            img: require('@/assets/images/banners/category-1.png'),
          },
          {
            img: require('@/assets/images/banners/category-2.png'),
          },
          {
            img: require('@/assets/images/banners/category-3.png'),
          },
          {
            img: require('@/assets/images/banners/category-1.png'),
          },
          {
            img: require('@/assets/images/banners/category-1.png'),
          },
          {
            img: require('@/assets/images/banners/category-2.png'),
          },
          {
            img: require('@/assets/images/banners/category-3.png'),
          },
          {
            img: require('@/assets/images/banners/category-1.png'),
          },
        ],
        slickSettings: {
          infinite: false,
          speed: 500,
          slidesToShow: 6,
          slidesToScroll: 6,
          initialSlide: 0,

          responsive: [
            {
              breakpoint: 2100,
              settings: {
                slidesToShow: 6,
                slidesToScroll: 6,
                infinite: true,
              },
            },
            {
              breakpoint: 1900,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
                infinite: true,
              },
            },
            {
              breakpoint: 1800,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
                infinite: true,
              },
            },
            {
              breakpoint: 1264,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
              },
            },
            {
              breakpoint: 960,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2,
              },
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              },
            },
          ],
        },
        slickSettingsTwo: {
          infinite: true,
          autoplay: true,
          speed: 500,
          slidesToShow: 4,
          slidesToScroll: 1,
          initialSlide: 0,

          responsive: [
            {
              breakpoint: 1700,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
              },
            },
            {
              breakpoint: 1264,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: true,
              },
            },
            {
              breakpoint: 960,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2,
              },
            },
            {
              breakpoint: 650,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 1,
              },
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              },
            },
          ],
        },
        slickSettingsThree: {
          infinite: false,
          speed: 500,
          slidesToShow: 6,
          slidesToScroll: 6,
          initialSlide: 0,

          responsive: [
            {
              breakpoint: 1700,
              settings: {
                slidesToShow: 6,
                slidesToScroll: 6,
                infinite: true,
              },
            },
            {
              breakpoint: 1264,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
                infinite: true,
              },
            },
            {
              breakpoint: 960,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                initialSlide: 3,
              },
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
              },
            },
          ],
        },
      }
    },
    methods: {
      ...mapActions(['addCart', 'removeCart']),
      getData () {
        this.loading = true
        // eslint-disable-next-line no-undef
        axios.get(this.url, { params: { per_page: this.total } }).then((response) => {
          this.loading = false
          if (response.data) {
            if (response.data.data) {
              this.items = response.data.data
            } else {
              this.items = response.data
            }
            if (this.storePath.length) {
              this.setValue({ type: this.storePath, value: this.items })
            }
            for (var i = 0; i < this.items.length; i++) {
              this.pushItem({ type: 'shops', field: 'id', value: this.items[i].id, object: this.items[i] })
            }
          }
        }).then(() => {
          this.loading = false
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
.top-categories-img {
  width: 385px;
  min-width: 100%;
  max-width: 100%;
}

</style>
