<template>
  <v-carousel
    class="vuetify-carousel"
    cycle
    :height="slideHeight"
    hide-delimiter-background
    show-arrows-on-hover
  >
    <v-carousel-item
      v-for="(n, index) in sliders"
      :key="index"
      :src="n.image"
      :href="n.url != 'null'?n.url:''"
    >
      <!--      <v-sheet
        height="100%"
        light
      >
        <v-row
          class="fill-height"
          align="center"
        >
          <v-col
            cols="7"
            lg="8"
            class="mb-14 mb-sm-0"
          >
            <v-col
              cols="12"
              xl="7"
              class="ms-3 ps-0"
            >
              <h1 class="show-3 font-weight-bold mb-4">
                {{ n.name }}
              </h1>
            </v-col>
            <v-col
              cols="12"
              xl="8"
            >
              <p class="secondary&#45;&#45;text banner-text mb-4">
                Lorem ipsum dolor sit amet, consectetur adipiscing
                elit. Quis lobortis consequat eu, quam etiam at quis
                ut convallis.
              </p>
            </v-col>
            <v-btn
              color="primary"
              class="text-capitalize ms-3"
            >
              Shop Now
            </v-btn>
          </v-col>
          <v-col
            cols="5"
            lg="4"
          >
            <v-img
              aspect-ratio="1"
              contain
              :src="n.img"
            />
          </v-col>
        </v-row>
      </v-sheet>-->
    </v-carousel-item>
  </v-carousel>
</template>

<script>

  import { mapActions, mapMutations, mapState } from 'vuex'

  export default {
    // name: 'HomeSlider',
    components: {
    },
    computed: {
      ...mapState({
        sliders: 'homeSliders',
      }),
      slideHeight () {
        if (this.$vuetify.breakpoint.lgAndUp) {
          return '500px'
        }
        if (this.$vuetify.breakpoint.mdAndUp) {
          return '300px'
        }
        if (this.$vuetify.breakpoint.smAndDown) {
          return '200px'
        }
      },
    },
    data () {
      return {
        heroSlider: [
          {
            img: require('@/assets/images/products/apple-watch-0.png'),
            name: '50% Off For Your First Shopping',
          },
          {
            img: require('@/assets/images/products/apple-watch-0.png'),
            name: ' 20% Off For  First Electronics Purchase',
          },
          {
            img: require('@/assets/images/products/apple-watch-0.png'),
            name: 'Get 70% Off For  First  Grocery Purchase',
          },
        ],

      }
    },
    created () {
      this.getData()
    },
    methods: {
      ...mapActions(['addCart', 'removeCart']),
      ...mapMutations([
        'pushItem', // map `this.increment()` to `this.$store.commit('increment')`
        'removeItem', // map `this.increment()` to `this.$store.commit('increment')`

        // `mapMutations` also supports payloads:
        'setValue', // map `this.incrementBy(amount)` to `this.$store.commit('incrementBy', amount)`
      ]),
      getData () {
        // eslint-disable-next-line no-undef
        axios.get('/home-sliders').then((response) => {
          if (response.data) {
            this.setValue({ type: 'homeSliders', value: response.data })
            //localStorage.setItem('planete@homeSliders', JSON.stringify(response.data))
          }
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
.top-categories-img {
  width: 385px;
  min-width: 100%;
  max-width: 100%;
}
.slick-slider {
  height: 100% !important;
}
</style>
